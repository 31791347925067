<script setup>
    import { ref, computed, watch } from 'vue';
    import BaseModal from '../BaseModal.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        item: Object,
        what: String,
        forAdmin: Boolean,
        provinces: Array,
    });

    /**************************************************************
     *      API Calling
     **************************************************************/
    const onSave = () => {
        const action = mode.value === 'Create' ? axios.post : axios.patch;
        const endpoint =
            mode.value === 'Create'
                ? '/api/v1/users'
                : `/api/v1/users/${record.value.id}`;

        const payload = {
            first_name: record.value.first_name,
            last_name: record.value.last_name,
            email: record.value.email,
            province_id: record.value.province_id,
            role: record.value.role,
            password: record.value.password,
            set_password: setPassword.value,
        };

        action(endpoint, payload)
            .then(response => {
                emit('close');
            })
            .catch(error => {
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Options
     **************************************************************/
    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { roleOptions, adminRoleOptions, clientRoleOptions } =
        storeToRefs(useOptionsStore());

    const record = ref({});
    const mode = ref('Create');

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});
    const defaultRecord = ref({
        first_name: '',
        last_name: '',
        email: '',
        province_id: null,
        role: null,
        password: '',
    });

    const setPassword = ref(true);

    /**************************************************************
     *      Watchers / Form Logic
     **************************************************************/

    // Watch for changes on input record in order to set state for update or create modes
    watch(
        () => props.item,
        recordToUpdate => {
            if (recordToUpdate) {
                record.value = {
                    ...recordToUpdate,
                    role: recordToUpdate.roles[0].name,
                };
                setPassword.value = false;
                mode.value = 'Update';
            } else {
                record.value = { ...defaultRecord.value };
                setPassword.value = true;
                mode.value = 'Create';
            }
        }
    );

    // Any time the modal reopens, clear the validation errors
    watch(
        () => props.isOpen,
        isOpen => {
            validationErrors.value = {};
        }
    );

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        :record="record"
        :title="`${mode} ${what}`"
        @close="onClose"
        @save="onSave"
    >
        <div class="grid grid-cols-2 gap-4">
            <v-text-field
                v-model="record.first_name"
                label="First Name"
                type="text"
                required
                outlined
                :error="validationErrors.first_name ? true : false"
                :error-messages="validationErrors.first_name"
            />
            <v-text-field
                v-model="record.last_name"
                label="Last Name"
                type="text"
                required
                outlined
                :error="validationErrors.last_name ? true : false"
                :error-messages="validationErrors.last_name"
            />
            <v-text-field
                v-model="record.email"
                label="Email"
                type="email"
                required
                outlined
                :error="validationErrors.email ? true : false"
                :error-messages="validationErrors.email"
            />
            <v-autocomplete
                v-model="record.province_id"
                :items="provinces"
                tabindex="3"
                item-value="id"
                item-title="name"
                :error="validationErrors.province_id	 ? true : false"
                :error-messages="validationErrors.province_id	"
                placeholder="Select Province / State"
                label="Province / State"
                clearable
            />
            <v-select
                v-model="record.role"
                :items="forAdmin ? adminRoleOptions : clientRoleOptions"
                label="Role"
                outlined
                required
                :error="validationErrors.role ? true : false"
                :error-messages="validationErrors.role"
            />
            <v-text-field
                v-if="mode == 'Create'"
                v-model="record.password"
                label="Password"
                type="password"
                outlined
                :error="validationErrors.password ? true : false"
                :error-messages="validationErrors.password"
            />
            <v-switch
                class="col-span-full"
                v-if="mode != 'Create'"
                v-model="setPassword"
                label="Override Password"
            />
            <div
                v-if="setPassword && mode != 'Create'"
                class="col-span-full flex flex-col gap-4 border rounded-xl p-4 bg-gray-200"
            >
                <div class="font-medium text-xl">Password Override</div>
                <div>
                    This will override the current password with the new given
                    password.
                </div>
                <v-text-field
                    v-if="setPassword"
                    v-model="record.password"
                    label="Password Override"
                    type="password"
                    outlined
                    :error="validationErrors.password ? true : false"
                    :error-messages="validationErrors.password"
                />
            </div>
        </div>
    </BaseModal>
</template>
