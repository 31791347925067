<script setup>
    import { ref, computed } from 'vue';
    import { router, usePage } from '@inertiajs/vue3';

    const { props } = usePage();

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});

    const form = ref({
        title: '',
        description: '',
    });

    const loading = ref(false);

    /**************************************************************
     *      Send Feedback
     **************************************************************/
    const sendFeedback = () => {
        loading.value = true;
        validationErrors.value = {};

        const payload = {
            ...form.value
        };

        axios
            .post(`/api/v1/users/${props.user.id}/feedback`, payload)
            .then(response => {
                toast.open({
                    message: 'Feedback sent successfully!',
                    type: 'success',
                });
                router.visit('/dashboard');
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            }).finally(() => {
                loading.value = false;
            });
    };

    /**************************************************************
     *      Breadcrumbs
     **************************************************************/
    const breadcrumbs = computed(() => {
        return [
            {
                title: 'Dashboard',
                disabled: false,
                href: '/dashboard',
            },
            {
                title: 'Feedback',
                disabled: false,
                href: '/feedback',
            },
        ];
    });
</script>
<template>
    <AuthenticatedLayout>
        <div class="py-8">
            <v-breadcrumbs class="-ml-3 mb-4" :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
            <div class="text-3xl self-center font-bold">Feedback</div>
            <p class="text-sm text-gray-500">
                Provide us with your feedback to help us improve our services.
            </p>
        </div>
        <form @submit.prevent="sendFeedback" class="flex flex-col gap-4">
            <v-text-field
                v-model="form.title"
                label="Title"
                type="text"
                required
                outlined
                :error="validationErrors.title ? true : false"
                :error-messages="validationErrors.title"
            />
            <v-textarea
                v-model="form.description"
                label="Description"
                type="text"
                required
                outlined
                :error="validationErrors.description ? true : false"
                :error-messages="validationErrors.description"
            />

            <v-spacer class="col-span-full"></v-spacer>

            <div class="flex justify-start">
                <v-btn :disabled="loading" @click="sendFeedback" color="primary">Send Feedback</v-btn>
            </div>
        </form>
    </AuthenticatedLayout>
</template>
