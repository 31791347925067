<script setup>
    import axios from 'axios';
    import { ref } from 'vue';

    import { router, Link } from '@inertiajs/vue3';

    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const phone = ref('');
    const province = ref(null);
    const password = ref('');
    const passwordConfirm = ref('');
    const validationErrors = ref({});
    const terms_and_conditions = ref(false);

    defineProps({
        provinces: {
            type: Array,
            required: true,
        },
    });

    const register = () => {
        validationErrors.value = {};

        axios.post('api/v1/register', {
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
                phone: phone.value,
                province: province.value,
                password: password.value,
                password_confirmation: passwordConfirm.value,
                terms_and_conditions: terms_and_conditions.value,
            })
            .then(response => {
                router.visit(response.data.href);
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }
            });
    };
</script>

<template>
    <img src="/imgs/logo.png" class="w-44 md:w-80 my-3 md:my-5">
    <form @submit.prevent="register" class="w-full max-w-96 px-4 md:px-0 flex flex-col -gap-2 border rounded border-grey-100 py-12 shadow-2xl">
        <h1 class="text-3xl text-center mb-8">Sign Up</h1>
        <v-text-field
            v-model="firstName"
            tabindex="1"
            label="First Name *"
            :clearable="false"
            type="text"
            outlined
            :error="validationErrors.first_name ? true : false"
            :error-messages="validationErrors.first_name"
        />
        <v-text-field
            v-model="lastName"
            tabindex="2"
            label="Last Name *"
            :clearable="false"
            type="text"
            outlined
            :error="validationErrors.last_name ? true : false"
            :error-messages="validationErrors.last_name"
        />
        <v-autocomplete
            v-model="province"
            :items="provinces"
            tabindex="3"
            item-value="id"
            class="mb-4"
            item-title="name"
            placeholder="Select Province / State"
            label="Province / State"
            compact
            clearable
            hide-details
        />
        <v-text-field
            v-model="email"
            label="Email *"
            tabindex="4"
            type="email"
            :clearable="false"
            outlined
            :error="validationErrors.email ? true : false"
            :error-messages="validationErrors.email"
        />
        <v-text-field
            v-model="password"
            label="Password *"
            tabindex="5"
            type="password"
            :clearable="false"
            outlined
            :error="validationErrors.password ? true : false"
            :error-messages="validationErrors.password"
        />
        <v-text-field
            v-model="passwordConfirm"
            label="Confirm Password *"
            type="password"
            tabindex="6"
            :clearable="false"
            outlined
            :error="validationErrors.password_confirm ? true : false"
            :error-messages="validationErrors.password_confirm"
        />
        <div class="flex gap-4 items-center">
            <v-checkbox v-model="terms_and_conditions" :error="validationErrors.terms_and_conditions ? true : false" :error-messages="validationErrors.terms_and_conditions">
                <template #label>
                    <div>I accept the <a href="https://www.theteacherssite.com/termsprivacy-policy" target="_blank" class="text-primary hover:text-green-400 font-extrabold">Terms And Conditions</a></div>
                </template>
            </v-checkbox>
        </div>
        <div class="col-span-2 flex flex-col gap-4">
        <v-btn block size="large" type="submit" color="primary">
            Register
        </v-btn>
        <div class="flex justify-center">
            <Link href="/login" class="self-center text-primary">
                <span class="text-gray-500">
                    Already have an account?
                </span>
                <span class="font-bold">Login</span>
            </Link>
        </div>
        </div>
    </form>
</template>
