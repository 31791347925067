<script setup>
    import { ref, computed } from 'vue';
    import { usePage } from '@inertiajs/vue3';

    const { props } = usePage();

    /**************************************************************
     *      Toast
     **************************************************************/
    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    /**************************************************************
     *      Form
     **************************************************************/
    const validationErrors = ref({});

    const setPassword = ref(false);
    const form = ref({
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        email: props.user.email,
        province_id: props.user.province_id,
        password: '',
        password_confirmation: '',
    });

    /**************************************************************
     *      Update Settings
     **************************************************************/
    const updateSettings = () => {
        validationErrors.value = {};

        const payload = {
            first_name: form.value.first_name,
            last_name: form.value.last_name,
            email: form.value.email,
            province_id: form.value.province_id,
            password: form.value.password,
            password_confirmation: form.value.password_confirmation,
            setPassword: setPassword.value,
        };

        axios
            .patch(`/api/v1/users/${props.user.id}/settings`, payload)
            .then(response => {
                toast.open({
                    message: 'Account updated successfully!',
                    type: 'success',
                });
            })
            .catch(error => {
                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
                if (error?.response?.data?.errors) {
                    validationErrors.value = error.response.data.errors;
                }
            });
    };

    /**************************************************************
     *      Breadcrumbs
     **************************************************************/
    const breadcrumbs = computed(() => {
        return [
            {
                title: 'Dashboard',
                disabled: false,
                href: '/dashboard',
            },
            {
                title: 'Account Settings',
                disabled: false,
                href: '/settings',
            },
        ];
    });
</script>
<template>
    <AuthenticatedLayout>
        <div class="py-8">
            <v-breadcrumbs class="-ml-3 mb-4" :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
            <div class="text-3xl self-center font-bold">Account Settings</div>
            <p class="text-sm text-gray-500">
                Update your account settings here.
            </p>
        </div>
        <form @submit.prevent="updateAccount" class="flex flex-col md:grid md:grid-cols-2 gap-4">
            <v-text-field
                v-model="form.first_name"
                label="First Name"
                type="text"
                required
                outlined
                :error="validationErrors.first_name ? true : false"
                :error-messages="validationErrors.first_name"
            />
            <v-text-field
                v-model="form.last_name"
                label="Last Name"
                type="text"
                required
                outlined
                :error="validationErrors.last_name ? true : false"
                :error-messages="validationErrors.last_name"
            />
            <v-text-field
                v-model="form.email"
                label="Email"
                type="email"
                required
                outlined
                :error="validationErrors.email ? true : false"
                :error-messages="validationErrors.email"
            />

            <v-autocomplete
                v-model="form.province_id"
                :items="props.provinces"
                item-value="id"
                item-title="name"
                placeholder="Select Province / State"
                label="Province / State"
                :error="validationErrors.province_id ? true : false"
                :error-messages="validationErrors.province_id"
                clearable
            />

            <v-switch
                class="col-span-full"
                v-model="setPassword"
                label="Change Password"
            />

            <v-text-field
                v-if="setPassword"
                v-model="form.password"
                label="Password"
                type="password"
                outlined
                :error="validationErrors.password ? true : false"
                :error-messages="validationErrors.password"
            />

            <v-text-field
                v-if="setPassword"
                v-model="form.password_confirmation"
                label="Confirm Password"
                type="password"
                outlined
                :error="validationErrors.password_confirmation ? true : false"
                :error-messages="validationErrors.password_confirmation"
            />

            <v-spacer class="col-span-full"></v-spacer>

            <div>
                <v-btn @click="updateSettings" color="primary">Update</v-btn>
            </div>
        </form>
    </AuthenticatedLayout>
</template>
