<template>
    <div>
        <v-toolbar
        dense
        floating
        >
            <v-btn icon @click="setHeading(1)">
                <v-icon>mdi-format-header-1</v-icon>
            </v-btn>
            <v-btn icon @click="setHeading(2)">
                <v-icon>mdi-format-header-2</v-icon>
            </v-btn>
            <v-btn icon @click="setHeading(3)">
                <v-icon>mdi-format-header-3</v-icon>
            </v-btn>
            <v-btn icon @click="setParagraph">
                <v-icon>mdi-format-paragraph</v-icon>
            </v-btn>
            <v-btn icon @click="toggleBold">
                <v-icon>mdi-format-bold</v-icon>
            </v-btn>
            <v-btn icon @click="toggleItalic">
                <v-icon>mdi-format-italic</v-icon>
            </v-btn>
            <v-btn icon @click="toggleBulletList">
                <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn icon @click="toggleOrderedList">
                <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>
            <v-btn icon @click="copyContent">
                <v-icon>mdi-content-copy</v-icon>
            </v-btn>
        </v-toolbar>

    <div class="w-full border-2 rounded">
        <editor-content :editor="editor" />
    </div>
    </div>
</template>

<script>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
  import StarterKit from '@tiptap/starter-kit';
  import { Editor, EditorContent } from '@tiptap/vue-3';
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";

  export default {
    components: {
      EditorContent,
    },

    props: {
      modelValue: {
        type: String,
        default: '',
      },
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const editor = ref(null);

      watch(
        () => props.modelValue,
        (value) => {
          if (!editor.value) return;

          // HTML
          const isSame = editor.value.getHTML() === value;

          // JSON
          // const isSame = JSON.stringify(editor.value.getJSON()) === JSON.stringify(value);

          if (isSame) {
            return;
          }

          editor.value.commands.setContent(value, false);
        }
      );

      onMounted(() => {
        editor.value = new Editor({
            extensions: [
            StarterKit.configure({
            bulletList: false,
            orderedList: false,
            heading: false,
          }),
          Heading.configure({ levels: [1, 2, 3] }),
          BulletList,
          OrderedList,
          HardBreak.configure({ keepMarks: true }),
            ],
          content: props.modelValue,
          onUpdate: () => {
            // HTML
            emit('update:modelValue', editor.value.getHTML());

            // JSON
            // emit('update:modelValue', editor.value.getJSON());
          },
        });
      });

      onBeforeUnmount(() => {
        if (editor.value) {
          editor.value.destroy();
        }
      });

      const setHeading = (level) => {
      if (editor.value) {
        editor.value.chain().focus().toggleHeading({ level }).run();
      }
    };

    const setParagraph = () => {
      if (editor.value) {
        editor.value.chain().focus().setParagraph().run();
      }
    };

    const toggleBold = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleBold().run();
      }
    };

    const toggleItalic = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleItalic().run();
      }
    };

    const toggleBulletList = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleBulletList().run();
      }
    };

    const toggleOrderedList = () => {
      if (editor.value) {
        editor.value.chain().focus().toggleOrderedList().run();
      }
    };

    const addLineBreak = () => {
      if (editor.value) {
        editor.value.chain().focus().setHardBreak().run();
      }
    };

    const copyContent = async () => {
      if (editor.value) {
        const content = editor.value.getHTML(); // Gets the HTML content of the editor
        try {
          await navigator.clipboard.writeText(content);
          alert("Content copied to clipboard!");
        } catch (err) {
          console.error("Failed to copy content: ", err);
          alert("Failed to copy content.");
        }
      }
    };

      return {
        editor,
        setHeading,
        setParagraph,
        toggleBold,
        toggleItalic,
        toggleBulletList,
        toggleOrderedList,
        addLineBreak,
        copyContent
      };
    },
  };
  </script>

  <style lang="scss">
  /* Basic editor styles */
  .tiptap {
    padding: 1rem;

    :first-child {
      margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
      padding: 0 1rem;
      margin: 1.25rem 1rem 1.25rem 0.4rem;

      li p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
      margin-top: 1rem;
      text-wrap: pretty;
    }

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1rem;
    }

    /* Code and preformatted text styles */
    code {
      background-color: var(--purple-light);
      border-radius: 0.4rem;
      color: var(--black);
      font-size: 0.85rem;
      padding: 0.25em 0.3em;
    }

    pre {
      background: var(--black);
      border-radius: 0.5rem;
      color: var(--white);
      font-family: 'JetBrainsMono', monospace;
      margin: 1.5rem 0;
      padding: 0.75rem 1rem;

      code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
      }
    }

    blockquote {
      border-left: 3px solid var(--gray-3);
      margin: 1.5rem 0;
      padding-left: 1rem;
    }

    hr {
      border: none;
      border-top: 1px solid var(--gray-2);
      margin: 2rem 0;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }
  }
</style>
