<script setup>
    import { ref, computed } from 'vue';
    const emit = defineEmits(['loadItems']);

    const props = defineProps({
        headers: {
            type: Array,
            required: true,
        },
        serverItems: {
            type: Array,
            required: true,
        },
        totalItems: {
            type: Number,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        searchTrigger: {
            type: Number,
            required: true,
        },
        itemsPerPageDefault: {
            type: Number,
            required: true,
        },
    });

    const itemsPerPage = ref(props.itemsPerPageDefault);

    const hasActions = computed(() => props.actions?.length > 0);
    const headers = computed(() => {
        const headers = props.headers.map(header => {
            return {
                ...header,
            };
        });

        if (hasActions.value) {
            headers.push({
                key: 'actions',
                title: 'Action',
                sortable: false,
                width: '100px',
            });
        }

        return headers;
    });

    const loadItems = async options => {
        options = {
            ...options,
            perPage: itemsPerPage.value,
        };
        emit('loadItems', options);
    };
</script>
<template>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :loading="loading"
        item-value="name"
        :search="searchTrigger.toString()"
        @update:options="loadItems"
        hover
    >
        <template v-if="hasActions" #[`item.actions`]="{ item }">
            <div class="flex">
                <div v-for="action in actions">
                    <v-btn
                        class="-mx-1"
                        color="primary"
                        variant="text"
                        :color="action.buttonColor"
                        @click="action.action(item)"
                        icon
                        size="small"
                    >
                        <v-icon size="small">
                            {{ action.icon }}
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </template>
        <template
            v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }"
        >
            <tr>
                <template v-for="column in columns" :key="column.key">
                    <th>
                        <span
                            class="mr-2 font-bold"
                            @click="() => toggleSort(column)"
                        >
                            {{ column.title }}
                        </span>
                        <template v-if="isSorted(column)">
                            <v-icon :icon="getSortIcon(column)"></v-icon>
                        </template>
                        <v-icon
                            v-if="column.removable"
                            icon="$close"
                            @click="() => remove(column.key)"
                        ></v-icon>
                    </th>
                </template>
            </tr>
        </template>
    </v-data-table-server>
</template>
