import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export const useOptionsStore = defineStore('options', () => {
    const roleOptions = ref(['Admin', 'Client']);
    const adminRoleOptions = ref(['Admin']);
    const clientRoleOptions = ref(['Client']);
    const userOptions = ref([]);
    const provinceOptions = ref([]);
    const reportCardStatusOptions = ref([]);

    /**
     * Generalized function to fetch options data from the API
     */
    const getOptionsData = async (key, endpoint) => {
        try {
            const response = await axios.get(endpoint);
            key.value = response.data;
        } catch (error) {
            console.error(`Failed to fetch ${key}:`, error);
        }
    };

    const fetchUserOptions = () => {
        getOptionsData(userOptions, '/api/v1/options/users');
    };

    const fetchProvinceOptions = () => {
        getOptionsData(provinceOptions, '/api/v1/options/provinces');
    };

    const fetchReportCardStatusOptions = () => {
        getOptionsData(
            reportCardStatusOptions,
            '/api/v1/options/report-card-statuses'
        );
    };

    return {
        // State
        roleOptions,
        userOptions,
        provinceOptions,
        adminRoleOptions,
        clientRoleOptions,
        reportCardStatusOptions,
        // Actions
        fetchUserOptions,
        fetchProvinceOptions,
        fetchReportCardStatusOptions,
    };
});
