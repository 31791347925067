<script setup>
    import axios from 'axios';
    import { ref } from 'vue';

    import { router, Link } from '@inertiajs/vue3';

    const email = ref('');
    const password = ref('');
    const validationErrors = ref({});

    defineProps({
        provinces: {
            type: Array,
            required: true,
        },
    });

    const login = () => {
        validationErrors.value = {};
        axios
            .post('api/v1/login', {
                email: email.value,
                password: password.value,
            })
            .then(response => {
                router.visit(response.data.href);
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }
            });
    };
</script>

<template>
    <div class="flex flex-col md:flex-row w-full h-screen">
        <div class="w-full md:h-auto py-8 md:py-0 md:w-1/2 bg-white flex flex-col items-center justify-center animate__animated animate__bounceInLeft px-4 md:px-0">
            <div class="animate__animated animate__flipInY w-full flex flex-col justify-center items-center">
                <img src="/imgs/logo.png" class="w-44 md:w-80 my-3 md:my-5">
                <form @submit.prevent="login" class="w-full max-w-96 px-4 md:px-0 flex flex-col -gap-2 border rounded border-grey-100 py-12 shadow-2xl">
                    <h1 class="text-3xl text-center mb-8">Sign In</h1>
                    <v-text-field
                        v-model="email"
                        label="Email"
                        type="email"
                        :clearable="false"
                        class="w-full"
                        outlined
                        :error="validationErrors.email ? true : false"
                        :error-messages="validationErrors.email"
                    />
                    <div class="flex flex-col">
                    <v-text-field
                        v-model="password"
                        :clearable="false"
                        label="Password"
                        type="password"
                        outlined
                        :error="validationErrors.password ? true : false"
                        :error-messages="validationErrors.password"
                    />
                        <Link class="text-sm self-end cursor-pointer text-primary" href="/forgot-password">Forgot Password?</Link>
                    </div>
                    <div class="col-span-2 flex flex-col gap-4 mt-4">
                        <v-btn
                            block
                            color="primary"
                            type="submit"
                            size="large"
                        >
                            Login
                        </v-btn>
                        <Link href="/sign-up" class="self-center cursor-pointer text-primary hover:text-black">
                            <span class="text-gray-500">
                                Don't have an account?
                            </span>
                            Sign Up
                        </Link>
                    </div>
                </form>
            </div>
        </div>
        <div class="hidden w-full md:w-1/2 bg-primary md:flex items-center justify-center px-12 lg:px-24 py-12 md:py-0">
            <img src="/imgs/teachers.svg" class="w-full" />
        </div>
        <img src="/imgs/teachers.svg" class="w-44 mx-auto md:hidden" />
    </div>
</template>
