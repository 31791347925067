import { defineStore } from 'pinia';
import { computed } from 'vue';

import { usePage } from '@inertiajs/vue3';

export const useUserStore = defineStore('user', () => {
    const userIsAdmin = computed(() => {
        const { props } = usePage();

        const roles = props?.auth?.user?.roles || [];

        const adminRoles = ['super_admin', 'admin'];

        const profileRoles = roles.map(role => role.name);

        return adminRoles.some(role => profileRoles.includes(role));
    });

    return {
        userIsAdmin,
    };
});
