<template>
    <v-responsive>
        <v-app class="overflow-hidden">
            <v-main class="bg-green-400 lg:h-screen flex lg:w-full items-center lg:first-line:flex overflow-hidden justify-center">
                <div>
                    <img class="max-w-24 p-2 mx-auto mb-3" src="/imgs/logo.png" />
                    <slot />
                </div>
            </v-main>
        </v-app>
    </v-responsive>
</template>
