<script setup>
    import axios from 'axios';
    import { ref, onMounted } from 'vue';
    import { Link } from '@inertiajs/vue3';

    const email = ref('');
    const code = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const isResetStep = ref(false);
    const validationErrors = ref({});

    import { useToast } from 'vue-toast-notification';
    const toast = useToast();

    // Function to parse query parameters from the URL
    const getQueryParams = () => {
        const params = new URLSearchParams(window.location.search);
        email.value = params.get('email') || '';
        code.value = params.get('reset_code') || '';
        isResetStep.value = !!email.value && !!code.value;
    };

    // Run the function on component mount to check the URL for parameters
    onMounted(() => {
        getQueryParams();
    });

    const requestReset = function () {
        axios
            .post('api/v1/forgot-password', {
                email: email.value,
            })
            .then(response => {
                toast.open({
                    message:
                        'Password reset email sent successfully! Please check your email.',
                    type: 'success',
                });

                isResetStep.value = true;
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }

                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
            });
    };

    import { router } from '@inertiajs/vue3';

    const resetPassword = () => {
        axios
            .post('api/v1/reset-password', {
                email: email.value,
                token: code.value,
                password: password.value,
                password_confirmation: confirmPassword.value,
            })
            .then(response => {
                toast.open({
                    message:
                        'Password reset successfully! Please login with your new password.',
                    type: 'success',
                });

                router.visit('/login');
            })
            .catch(error => {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                ) {
                    if (error?.response?.data?.errors) {
                        validationErrors.value = error.response.data.errors;
                    }
                }

                toast.open({
                    message: 'Something went wrong! Please try again.',
                    type: 'error',
                });
            });
    };
</script>

<template>
    <img src="/imgs/logo.png" class="w-80 my-5">
    <form @submit.prevent="isResetStep ? resetPassword() : requestReset()" class="w-full max-w-96 px-4 md:px-0 flex flex-col -gap-2 border rounded border-grey-100 py-12 shadow-2xl">
        <h1 class="text-3xl text-center mb-8">{{ isResetStep ? 'Reset Password' : 'Password Reset' }}</h1>
                <v-text-field
                    v-if="!isResetStep"
                    v-model="email"
                    label="Email"
                    type="email"
                    required
                    outlined
                    :error="validationErrors.email ? true : false"
                    :error-messages="validationErrors.email"
                />
                <template v-if="isResetStep">
                    <v-text-field
                        :disabled="true"
                        v-model="email"
                        label="Email"
                        type="email"
                        class="mt-6"
                        outlined
                        :error="validationErrors.email ? true : false"
                        :error-messages="validationErrors.email"
                        readonly
                    />
                    <v-text-field
                        v-model="code"
                        label="Code"
                        class="mt-4"
                        outlined
                        :error="validationErrors.token ? true : false"
                        :error-messages="validationErrors.token"
                    />
                    <v-text-field
                        v-model="password"
                        label="New Password"
                        type="password"
                        class="mt-4"
                        outlined
                        :error="validationErrors.password ? true : false"
                        :error-messages="validationErrors.password"
                        required
                    />
                    <v-text-field
                        v-model="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        class="mt-4"
                        outlined
                        :error="validationErrors.password ? true : false"
                        :error-messages="validationErrors.password"
                        required
                    />
                </template>
                <div class="col-span-2 flex flex-col gap-4">
                <v-btn block type="submit" color="primary" size="large">
                    {{ isResetStep ? 'Reset Password' : 'Send Reset Email' }}
                </v-btn>
                <div class="flex justify-center">
                    <Link href="/login" class="self-center">
                        <span class="text-gray-500">
                            Back to Login
                        </span>
                    </Link>
                </div>
        </div>
    </form>
</template>
