<script setup>
    import { ref } from 'vue';

    /**************************************************************
     *      Modal
     **************************************************************/
    import UserModal from '../../Components/Modals/UserModal.vue';
    const isModalOpen = ref(false);
    const selectedUser = ref(null);

    const showUpdateModal = item => {
        selectedUser.value = item;
        isModalOpen.value = true;
    };

    const showCreateModal = () => {
        selectedUser.value = null;
        isModalOpen.value = true;
    };

    const closeModal = () => {
        isModalOpen.value = false;
        searchTrigger.value = Date.now(); // cause the table to reload
    };

    defineProps({
        provinces: Array,
    });

    /**************************************************************
     *      Table
     **************************************************************/
    const headers = [
        {
            title: 'Name',
            key: 'name',
            value: item => item.first_name + ' ' + item.last_name,
            sortable: false,
        },
        { title: 'Email', key: 'email', sortable: false },
        { title: 'Province', key: 'province.name', sortable: false },
        { title: 'Report Card Count', key: 'report_cards_count', sortable: false },
        {
            title: 'Role',
            key: 'role',
            value: item => String(item.roles[0].name).charAt(0).toUpperCase() + String(item.roles[0].name).slice(1),
            sortable: false,
        },
    ];

    const searchTrigger = ref(Date.now());

    import { storeToRefs } from 'pinia';
    import { useOptionsStore } from '@/stores/options';
    const { adminRoleOptions } = storeToRefs(useOptionsStore());

    const additionalParams = ref({
        search: '',
        role: null,
    });
</script>
<template>
    <AuthenticatedLayout>
        <UserModal
            :isOpen="isModalOpen"
            :item="selectedUser"
            @close="closeModal"
            :provinces="provinces"
            what="Admin User"
            :forAdmin="true"
        />
        <TableViewManager
            title="Admin Users"
            :headers="headers"
            subtitle="This is where you can view a listing of all Admin users."
            endpoint="/api/v1/users/admins"
            :searchTrigger="searchTrigger"
            :additionalParams="additionalParams"
            :actions="[
                {
                    title: 'Action',
                    icon: 'mdi-pencil',
                    buttonText: 'Edit',
                    buttonColor: 'secondary',
                    action: item => showUpdateModal(item),
                },
            ]"
        >
            <template #actions>
                <v-btn color="primary" class="px-4" @click="showCreateModal">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </template>
            <template #filters>
                <v-text-field
                    v-model="additionalParams.search"
                    label="Search"
                    dense
                    hide-details
                ></v-text-field>
                <v-select
                    v-model="additionalParams.role"
                    :items="adminRoleOptions"
                    label="Role"
                    clearable
                    dense
                    hide-details
                />
            </template>
        </TableViewManager>
    </AuthenticatedLayout>
</template>
