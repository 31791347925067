<script setup>
    import { ref, watch, computed } from 'vue';

    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        subtitle: {
            type: String,
            default: '',
        },
        endpoint: {
            type: String,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        additionalParams: {
            type: Object,
            default: () => ({}),
        },
        searchTrigger: {
            type: Number,
            required: true,
        },
        hideBreadcrumbs: {
            type: Boolean,
            default: false,
        },
        itemsPerPageDefault: {
            type: Number,
            default: 15,
        },
    });

    const serverItems = ref([]);
    const totalItems = ref(0);
    const loading = ref(false);

    import debounce from 'lodash.debounce';
    const loadItems = async options => {
        loading.value = true;

        const opts = {
            ...options,
            ...props.additionalParams,
        };

        try {
            const response = await axios.get(props.endpoint, { params: opts });
            serverItems.value = response.data.data;
            totalItems.value = Number(response.data.total);
        } catch (error) {
            console.error(error);
        } finally {
            loading.value = false;
        }
    };

    const debouncedLoadItems = debounce(loadItems, 300);

    import { usePage } from '@inertiajs/vue3';
    const page = usePage();
    const currentPath = computed(() => page.url);

    const breadcrumbs = computed(() => {
        const pathArray = currentPath.value.split('/').filter(path => path);

        let paths = pathArray.map((path, index) => {
            return {
                title: path.charAt(0).toUpperCase() + path.slice(1),
                disabled: false,
                href: '/' + pathArray.slice(0, index + 1).join('/'),
            };
        });

        paths.unshift({
            title: 'Dashboard',
            disabled: false,
            href: '/dashboard',
        });

        return paths;
    });

    watch(
        () => props.additionalParams,
        () => {
            debouncedLoadItems();
        },
        { deep: true }
    );
</script>
<template>
    <div class="py-8">
        <v-breadcrumbs
            v-if="!props.hideBreadcrumbs"
            class="-ml-3 mb-4"
            :items="breadcrumbs"
        >
            <template v-slot:divider>
                <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
        </v-breadcrumbs>
        <div class="flex justify-between">
            <div>
                <div class="text-3xl self-center font-bold">{{ title }}</div>
                <p class="text-sm text-gray-500">
                    {{ subtitle }}
                </p>
            </div>

            <div class="flex gap-2 self-center">
                <slot name="actions"></slot>
            </div>
        </div>
        <div class="flex flex-col md:grid grid-cols-4 gap-4 pb-10 mt-4">
            <slot name="filters" />
        </div>
    </div>
    <TableView
        :searchTrigger="searchTrigger"
        :headers="headers"
        :serverItems="serverItems"
        :totalItems="totalItems"
        :loading="loading"
        :actions="actions"
        :itemsPerPageDefault="props.itemsPerPageDefault"
        @loadItems="debouncedLoadItems"
    ></TableView>
</template>
