<script setup>
    import { ref } from 'vue';
    import BaseModal from '../BaseModal.vue';
    import { useClipboard } from '@vueuse/core';

    import TipTap from '../TipTap.vue';

    /**************************************************************
     *      Props
     **************************************************************/
    const props = defineProps({
        isOpen: Boolean,
        comments: Object,
    });

    const emit = defineEmits(['close']);
    const onClose = () => {
        emit('close');
    };

    const commentsTextArea = ref('');
    const { copy, isSupported } = useClipboard({ commentsTextArea });

    const capitalizeFirstLetter = (val) => {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    const convertObjectToHTML = (data) => {
        // Create unordered list for strength responses
        const strengthList = data.strengths.map(response => `<li>${capitalizeFirstLetter(response)}</li>`).join('');
        const strengthHTML = `<h1><strong>Areas Of Strength</strong></h1><ul>${strengthList}</ul>`;

        // Create unordered list for growth responses
        const growthList = data.growths.map(response => `<li>${capitalizeFirstLetter(response)}</li>`).join('');
        const growthHTML = `<h1><strong>Areas Of Growth</strong></h1><ul>${growthList}</ul>`;

        // Create paragraph for additional narrative response
        const narrativeHTML = `<p>${capitalizeFirstLetter(data.additional)}</p>`;

        // Combine all parts into a single HTML string
        return `${data.strengths.length ? strengthHTML : ''}${data.growths.length ? growthHTML : '' }${data.additional ? narrativeHTML : ''}`;
    }

    commentsTextArea.value = convertObjectToHTML(props.comments);
</script>

<template>
    <BaseModal
        :isOpen="isOpen"
        title="Report Card Comments"
        @close="onClose">
        <div class="flex flex-col gap-4 items-center w-full">
            <div class="w-full lg:min-h-screen-1/2">
                <TipTap v-model="commentsTextArea" />
            </div>

            <div v-if="isSupported">
                <v-btn color="success" @click="copy(commentsTextArea)">Copy <v-icon>mdi-content-copy</v-icon></v-btn>
            </div>
        </div>
    </BaseModal>
</template>
