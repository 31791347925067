<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    configs: {
        type: Array,
        required: true,
    },
});

const search = ref('');

/**************************************************************
 *      Breadcrumbs
 **************************************************************/
    const breadcrumbs = computed(() => {
    return [
        {
            title: 'Dashboard',
            disabled: true,
            href: '#',
        },
    ];
});

const reportCardConfigs = ref(props.configs);

const searchConfigs = (page = 1) => {
    axios.get('/api/v1/report-cards/search', {
        params: {
            search: search.value,
            page: page
        }
    })
    .then(response => {
        reportCardConfigs.value = response.data?.configs;
    });
};
</script>
<template>
    <AuthenticatedLayout>
        <ReportCardModal v-if="modal" :isOpen="modal" @close="modal = false" :hide-save="true" cancel-text="Close" :comments="generatedComment" />
        <div class="py-8">
            <v-breadcrumbs class="-ml-3 mb-4" :items="breadcrumbs">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
            <div class="text-3xl self-center font-bold">Report Card Dashboard</div>
            <p class="text-sm text-gray-500">
                Listing for all the report card configurations you have created.
            </p>
        </div>


        <div class="flex md:gap-4 flex-col md:flex-row mb-8 md:md-0">
            <v-text-field
                v-model="search"
                label="Search"
                outlined
                dense
                v-debounce:300ms="searchConfigs" :debounce-events="'input'"
                class="w-full"
                placeholder="Search by name"
            />
            <v-btn color="primary" class="px-4" @click="$inertia.visit('/report-cards')">
                Create New
                <v-icon class="self-center ml-2">mdi-plus</v-icon>
            </v-btn>
        </div>

        <div class="flex flex-col md:grid md:grid-cols-2 gap-4" v-if="reportCardConfigs.data?.length">
            <div v-for="config in reportCardConfigs?.data" :key="config.id" class="bg-primary transition-opacity duration-300 ease-in-out hover:opacity-70 shadow-lg cursor-pointer rounded p-4" @click="$inertia.visit('/report-cards/' + config.uuid)">
                <div class="flex justify-between items-center gap-4">
                    <div>
                        <h3 class="font-bold text-lg truncate flex items-center gap-2"><v-icon>mdi-book-cog</v-icon>{{ config.name }}</h3>
                        <p class="mt-2 italic">{{ config.subject.name }}</p>
                        <p class="font-thin">{{ config.curriculum.name }}</p>
                        <p class="text-sm bg-gray-200 rounded-2xl py-1 inline-block mt-2 px-3 text-gray-600 font-bold">{{ config.indicator_format.name }}</p>

                        <div class="cursor-pointer text-underline italic mt-4 font-bold truncate">
                            Continue Where You Left Off
                        </div>
                    </div>
                    <div class="text-3xl">
                        <v-icon icon="mdi-chevron-right" />
                    </div>
                </div>
            </div>
        </div>

        <v-pagination @update:model-value="searchConfigs" v-if="reportCardConfigs.data?.length" class="mt-8" :length="reportCardConfigs?.last_page"></v-pagination>

        <div v-else class="flex flex-col gap-4 justify-center items-center mt-12 md:mt-24">
            <div class="text-3xl font-thin">Hmmm... No Report Card Configs Found. Lets Create One.</div>

            <div class="mt-8">
                <v-btn size="x-large" color="primary" class="px-4" @click="$inertia.visit('/report-cards')">
                    Create New
                    <v-icon class="self-center ml-2">mdi-plus</v-icon>
                </v-btn>
            </div>
        </div>
    </AuthenticatedLayout>
</template>
